.app-wrapper() {
  max-width: 1920px;
  min-width: 1024px;
  margin: 0 auto;
  padding: 0 40px;
}

.breakpoint-sm(@rules) {
  @media only screen and (min-width: 576px) {
    @rules();
  }
}

.breakpoint-md(@rules) {
  @media only screen and (min-width: 768px) {
    @rules();
  }
}

.breakpoint-lg(@rules) {
  @media only screen and (min-width: 992px) {
    @rules();
  }
}

.breakpoint-xl(@rules) {
  @media only screen and (min-width: 1200px) {
    @rules();
  }
}

.breakpoint-xxl(@rules) {
  @media only screen and (min-width: 1600px) {
    @rules();
  }
}

.breakpoint-xxxl(@rules) {
  @media only screen and (min-width: 1900px) {
    @rules();
  }
}

.breakpoint-xxxxl(@rules) {
  @media only screen and (min-width: 2900px) {
    @rules();
  }
}

.breakpoint(@width, @rules) {
  @media only screen and (min-width: @width) {
    @rules();
  }
}
